.col {
  margin: 10px 30px;
}

.flip_card {
  background-color: transparent;
  width: 100%;
  height: 28rem;
  perspective: 1000px;
  font-family: sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.flip_card_inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip_card:hover .flip_card_inner {
  transform: rotateY(180deg);
}

.flip_card_front,
.flip_card_back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgb(28, 4, 123);
  border-radius: 1rem;
}

.flip_card_front {
  background: linear-gradient(
    120deg,
    rgb(16, 79, 117) 60%,
    rgb(38, 131, 181) 88%,
    rgb(88, 196, 235) 40%,
    rgb(38, 131, 181, 0.603) 48%
  );
  color: rgb(242, 233, 230);
}

.flip_card_back {
  background: linear-gradient(
    120deg,
    rgb(159, 219, 234) 30%,
    rgb(92, 147, 176) 88%,
    rgb(118, 204, 236) 40%,
    rgb(38, 131, 181) 78%
  );
  color: rgb(6, 9, 63);
  transform: rotateY(180deg);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 5px;
}

.img_body{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  width: 12rem;
  padding: 20% 0;
  margin: auto;
}

.card_body {
  height:25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1200px) {
  .text_body{
    font-size:14.5px;
  }
}

@media only screen and (max-width: 992px) {
  .col {
    margin: 8px 20px;
  }
  .img {
    width: 13.5rem;
  }
  .card_body {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .col {
    margin: 6px 15px;
  }
  .img {
    width: 9.5rem;
  }
  .card_body {
    font-size: 0.8rem;
  }
  .flip_card{
    height:22rem;
  }
  .text_body{
    font-size:12px;
  }
}

@media only screen and (max-width: 576px) {
  .col {
    margin: 4px 10px;
  }
  .img {
    width: 7rem;
  }
  .card_body {
    font-size: 0.6rem;
  }
  .flip_card{
    height:18rem;
  }
  .text_body{
    font-size:9px;
  }
}
@media only screen and (max-width: 410px) {
  .col {
    margin: 2px 8px;
  }
  .img {
    padding: 0px;
    width: 3.8rem;
  }
  .card_body {
    font-size: 0.4rem;
  }
  .flip_card{
    height:12rem;
  }
  .text_body{
    font-size:5px;
  }
}
