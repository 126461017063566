.col{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
}
.img{
    width: 10rem;
}

@media only screen and (max-width: 992px) {
    .col{
        margin: 1.6rem;
    }
    .img{
        width: 8.5rem;
    }
}
 
@media only screen and (max-width: 768px) {
    .col{
        margin: 1.2rem;
    }
    .img{
        width: 7rem;
    }
}
 
@media only screen and (max-width: 576px) {
    .col{
        margin: 0.8rem;
    }
    .img{
        width: 5.5rem;
    }
}
@media only screen and (max-width: 310px) {
    .col{
        margin: 0.4rem;
    }
    .img{
        width: 4rem;
    }
}
 