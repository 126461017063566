* {
  margin: 0;
  padding: 0;
}
.navitat {
  margin: 0;
  padding: 0;
}
.logo-img {
  height: 3rem;
  margin: 0;
}
.nav-item {
  font-size: 1.2rem;
  text-align: center;
}
.nav-link {
  display: block;
  color: black;
  font-weight: 500;
  padding: 2px 4px;
  width: 10rem;
  height: 2.2rem;
}
.nav-link:hover {
  color: rgb(17, 22, 178);
  text-decoration: underline;
  text-underline-offset: 10px;
  cursor: pointer;
}


.iconbody{
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid rgb(205, 197, 197); 
  border-bottom-style: groove;
 } 
 .iconbox{
  width:2em ;
  height:2em ;
  margin: 5px 10px;
 }
.icon {
  width:1.7em ;
  height:1.7em ;
  color: #14dcaf;
}
.icon:hover{
  color: #131767;
  width:1.9em ;
  height:1.9em ;
  transition:200ms;
}



@media only screen and (max-width: 992px) {
  .logo-img {
    height: 2.5rem;
  }
  .nav-item {
    font-size: 1rem;
    text-align: left;

  }
  .nav-link{
  height: 2rem;
  }
  .nav-link:hover {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 768px) {
  .logo-img {
    height: 2rem;
  }
  .nav-item {
    font-size: 0.8rem;
    text-align: left;
  }
  .nav-link{
    height: 1.8rem;
    }
  .nav-link:hover {
    font-size: 0.9rem;
  }
  
}

@media only screen and (max-width: 576px) {
  .logo-img {
    height: 1.5rem;
  }
  .nav-item {
    font-size: 0.6rem;
    text-align: left;
  }
  .nav-link{
    height: 1.4rem;
    }
  .nav-link:hover {
    font-size: 0.7rem;
  }
  
}
