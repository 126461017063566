
.head {
  font-size: 3rem;
  margin: 2rem 0;
  text-align: center;
}
.card{
  display:flex;
  flex-wrap:wrap;
}

@media only screen and (max-width: 992px) {
  .head {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
}
@media only screen and (max-width: 768px) {
  .head {
    font-size: 1rem;
    margin: 1rem 0;
  }
}
@media only screen and (max-width: 576px) {
  .head {
    font-size: 0.8rem;
    margin: 0.5rem;
  }
}