.container {
  width: 100%;
  position:absolute;
  top:2%;
}
.body {
  padding: 9% 4%;
  text-align: left;
  animation-delay: 200ms;
  animation-duration: 1.5s;
}
.typewriter {
  color: white;
  align-items:left;
  font-size: 3.5rem;
  font-weight: bold;
}
.heading3 {
  color: #f7db4b;
  font-size: 2.3rem;
  font-weight: bold;
  margin-top: 4.5rem;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-animation-name: hover;
  animation-name: hover;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@keyframes hover {
  50% {
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
  }

  100% {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
 .iconbody{
  display: flex;
  flex-direction: row; 
 } 
 .iconbox{
  width:3em ;
  height:3em ;
  margin: 5px 2%;
 }
.icon {
  width:2.5em ;
  height:2.5em ;
  color: #f7fcfb;
}
.icon:hover{
  color: #11b894;
  width:2.8em ;
  height:2.8em ;
  transition:200ms;
}

@media only screen and (max-width: 1050px) {
  
  .typewriter {
    font-size: 2.5rem;
  }
  .heading3 {
    font-size: 1.2rem;
    margin-top: 3.5rem;
  }
}

@media only screen and (max-width: 768px) {

  .typewriter {
    font-size: 1.5rem;
  }
  .heading3 {
    font-size: 0.8rem;
    margin-top: 3rem;
  }
  .iconbox{
    width:2em ;
    height:2em ;
    margin: 4px 2%;
   }
  .icon {
    width:1.5em ;
    height:1.5em ;
  }
  .icon:hover{
    width:1.8em ;
    height:1.8em ;
  }
}

@media only screen and (max-width: 576px) {
  .body {
    padding:10% 4%;
  }
  .typewriter {
    font-size: 0.9rem;
    margin-top: 1.5rem;
  }
  .heading3 {
    font-size: 0.55rem;
    margin-top: 1.3rem;
  }
  .iconbox{
    width:1.3em ;
    height:1.3em ;
    margin: 3px 2%;
   }
  .icon {
    width:0.8em ;
    height:0.8em ;
  }
  .icon:hover{
    width:1.1em ;
    height:1.1em ;
  }
}
@media only screen and (max-width: 390px) {
  .typewriter {
    font-size: 0.6rem;
  }
  .heading3 {
    font-size: 0.3rem;
    margin-top: .3rem;
  }
  .iconbox{
    width:1em ;
    height:1em ;
    margin: 2px 2%;
   }
  .icon {
    width:0.5em ;
    height:0.5em ;
  }
  .icon:hover{
    width:0.8em ;
    height:0.8em  ;
  }
}