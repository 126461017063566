.container {
  background-image: url("Contact Us.png");
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 36rem;
}
.head {
  text-align: center;
  color: white;
  padding: 2rem;
  font-size: 3rem;
}
.para{
  color:white;
  padding: 0 5rem;
  font-size: 2.2rem;
  text-align: center;
}
.text {
  color: white;
  font-size: 2rem;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .head {
    font-size: 2rem;
    padding: 1.5rem;
  }
  .para{
    padding: 0 4rem;
    font-size: 1.4rem;
  }
  .text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    height: 21rem;
  }
  .head {
    font-size: 1rem;
    padding: 0.8rem;
  }
  .para{
    padding: 0 3rem;
    font-size: 1rem;
  }
  .text {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 576px) {
  .container {
    height: 18rem;
  }
  .head {
    font-size: 0.8rem;
    padding: 0.6rem;
  }
  .para{
    padding: 0 2rem;
    font-size: 0.8rem;
  }
  .text {
    font-size: 0.6rem;
  }
}
