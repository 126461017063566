.col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col div{
  color: white;
  font-size: 1.6rem;
  text-align: center;
}
.img {
  width: 90%;
}


@media only screen and (max-width: 992px) {

  .col div{
    font-size: 1.2rem;
    padding: 5% 2% 0% 2%;
  }
  .img {
    margin-top:30px;
    width: 65%;
  }
}
@media only screen and (max-width: 768px) {

  .col div{
    font-size: 0.8rem;
    padding: 10% 5% 0% 5%;
  }
  .img {
    margin-top:20px;
    width: 60%;
  }
}
@media only screen and (max-width:576px){

  .col div{
    padding: 5% 3% 0% 3%;
    font-size: 0.6rem;
  }
}