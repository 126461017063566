.container {
  padding: 2% 0%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.col {
  height: 17rem;
}
.head {
  padding-top: 10%;
  color: #f7db4b;
  font-size: 17px;
}
.body {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
}
.body div {
  margin: auto;
}

@media only screen and (max-width: 992px) {
  .head {
    padding-top: 8%;
    font-size: 15px;
  }
  .body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .head {
    padding-top: 6%;
    font-size: 13px;
  }
  .body {
    font-size: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .head {
    padding-top: 4%;
    font-size: 11px;
  }
  .body {
    font-size: 8px;
  }
  .row{
    margin: 0;
  }
}
