.container {
  background: url("/public/Images/wave.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.body {
  padding-top: 40px;
  display: flex;
  position: realtive;
  align-items: center;
}

.body img {
  position: absolute;
  width: 10%;
}

.head {
  width: 100%;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  text-align: center;
  
}

@media only screen and (max-width: 992px) {
  .head {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .head {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .head {
    font-size: 0.8rem;
  }
}
